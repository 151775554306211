html {
	font-size: 80%;
}

html .invalid-feedback {
	font-size: 100%;
}

.manual-menu {

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: #3d5268;
		color: #333;
	}
}


@media (min-width: 1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1140px
	}

}

// simulation table
table.simulation-table {
	font-weight: 400;
	line-height: 1.5;
	color: #495057;

	line-height: 1.5;

	td.main {
		vertical-align: top;
	}

	font-size: 12px;
	padding: 5px;
	margin: 5px auto;

	div.image-area {
		// padding: 50px 0;
		border: 3px solid #bbb;
		min-width: 400px;
		height: 690px;
		text-align: center;
		display: table-cell;
		vertical-align: middle;
	}

	.jobno,
	.concept-title {
		font-size: 14px;
	}

	.jobno {
		text-align: right;
	}

	div.explain {
		margin-top: 5px;
		font-size: 13px;
		text-align: center;
	}

	div.title {
		font-size: 14px;
		width: 200px;
		margin: 5px;
		padding: 5px 8px;
		display: inline-block;
		color: white;
		background-color: #333;
	}

	hr.dashed {
		border-top: 3px dashed #bbb;
		border-bottom: 0px;
		margin-top: 5px;
		margin-bottom: 10px;
	}

	img {
		max-height: 690px;
		max-width: 400px;
	}
}

table.result-table.norm {
	tr td:not(:first-child) {
		width: 75px;
	}

	tr td:first-child {
		width: 100px;
	}
}

table.correct-table {
	font-size: 12px;
	border-collapse: collapse;

	th,
	td {
		border: 1px solid #bbb;
		padding: 2px;
		text-align: center;
	}

	th {
		background-color: #ddd;
	}

	td {
		background-color: white;
	}
}

table.result-table {
	font-size: 12px;
	border-collapse: collapse;

	td.label-area,
	td.goal,
	td.total {
		background-color: #ddd;
	}

	th,
	td {
		border: 1px solid #bbb;
		padding: 2px;
	}

	tr:first-child th:first-child {
		border: 0px;
		background-color: white;
	}

	th {
		background-color: #ddd;
	}

	tr td:not(:first-child) {
		width: 100px;
	}

	tr td:first-child {
		width: 155px;
	}

	th,
	td {
		text-align: center;
	}

	th.subtitle {
		text-align: left;
	}

	td.minus {
		color: red;
	}
	.originality,.strategy__originality{
		background-color: #c00000 !important;
		color: white;
	}
	.action-standard {
		background-color: #c00000 !important;
		color: white;
	}

	tr.action-standard {
		td {
			background-color: #c00000;
			color: white;
		}

		td.none-value {
			border: 0px;
			background-color: white;
		}
	}

	td.trial {
		background-color: #FBC2A4 !important;
	}

	td.repeat {
		background-color: #FEE6AA !important;
	}

	td.localOther {
		background-color: #CDF1BA !important;
	}

	td.inbound {
		background-color: #C1D4F4 !important;
	}

	tr td.reason-area {
		width: 500px;
	}

	.form-control {
		height: auto !important;
		padding: 1px;
	}

	input.form-control {
		font-size: 12px;
	}

	textarea.form-control {
		font-size: 11px;
		resize: none;
		overflow: hidden;
	}

	input.form-control {
		text-align: center;
	}

	textarea.form-control {
		letter-spacing: -0.1px;
	}
}

table.wrap-table {
	width: 100%;

	table.result-table {
		width: 100%;
	}

	td.table-area {
		table {
			margin: 13px 0;
		}
	}
}

table.sub-table td.title-area {
	padding: 0px 7px 0px 4px;
	text-align: center;
}

table.simulation {
	margin-top: 3px;
}

span.small-font {
	font-size: 9px;
}